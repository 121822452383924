<template>
  <div id="contact">
    <app-navbar></app-navbar>
    <div class="container contact-block">
      <div class="crossBallone">
        <img v-parallax="0.2" class="img-fluid" src="@/assets/crossRedBallone.png" alt="whiteBallone">
      </div>
      <div class="row">
        <app-contact-row v-for="contact in contacts" v-bind:key="contact.name | translation"
                         v-bind:contact="contact"></app-contact-row>
      </div>
    </div>
    <div class="row no-gutters whiteBallone">
      <div class="col-lg-1 col-4 offset-lg-1 offset-0">
        <img v-parallax="0.1" class="img-fluid" src="@/assets/whiteBallone.png" alt="whiteBallone">
      </div>
      <div class="col-lg-1 col-4">
        <img v-parallax="0.1" class="img-fluid" src="@/assets/whiteBallone.png" alt="whiteBallone">
      </div>
    </div>
    <div class="col-lg-1 col-4 offset-lg-11 offset-8 redBallone">
      <img v-parallax="0.2" width="40%" class="img-fluid" src="@/assets/redBallone.png" alt="whiteBallone">
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import navbar from "@/components/base/navbar";
import contactRow from "@/components/base/contactRow";
import axios from "../../api/axiom-instance";
import footer from "@/components/base/footer";

export default {
  name: "contact",
  components: {
    'app-navbar': navbar,
    'app-contact-row': contactRow,
    'app-footer': footer
  },
  data() {
    return {
      contacts: []
    }
  },
  created() {
    axios
        .get("/contact/list")
        .then((success) => {
          this.contacts = success.data
        }).catch((failed) => {
      console.log(failed)
    })

  }
}
</script>

<style scoped>
h1 {
  font-size: 2.5em;
  padding-top: 3em;
  padding-bottom: 3.5em;
  font-family: "Transcript Pro";
}
.contact-block {
  padding-top: 13rem;
  min-height: 70vh;
}

#contact {
  background-color: #FFCE6D;
}

.whiteBallone {
  padding-bottom: 3em;
}

.redBallone {
  padding-bottom: 1.4em;
}

.crossBallone {
  width: 20%;
  position: absolute;
  top: 16%;
  text-align: end;
  right: 10%;
}

@media (max-width: 400px) {
  .crossBallone {
    position: absolute;
    top: 26%;
    width: 40%;
    text-align: end;
    right: 10%;
  }

  h1 {
    padding-bottom: 1em;
  }
}

/*
případně odstranit
.photoLinkFB {
  padding-top: 8em;
  margin-top: 8em;
  background-image: url("~@/assets/LinkFbBackground2.jpg");
  background-position: bottom;
  background-size: cover;
  height: 50vh;
}
*/
@media (min-width: 1200px) {
  .container {
    max-width: 970px;
  }
}

/*
případně odstranit
@media screen and (max-width: 500px) {
  .photoLinkFB {
    padding-top: 4em;
  }
}
*/
.btn {
  color: white;
  font-family: "Transcript Pro";
  border: solid 1px white;
  padding: 10px 25px 10px 25px;
  border-radius: 5px;
}

.btn:hover {
  color: black;
  background-color: white;

}

</style>
